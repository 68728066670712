'use client';
import { motion } from 'framer-motion';
import { Mic, FileText, Globe, Users } from 'lucide-react';
import Link from 'next/link';
import { useEffect } from 'react';
import { useState } from 'react';

// Add this component to handle client-side initialization
const ClientOnly = ({ children }: { children: React.ReactNode }) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) return null;

  return <>{children}</>;
};

export function LandingPage() {
  return (
    <ClientOnly>
      <div className='flex flex-col min-h-screen bg-gradient-to-b from-green-50 to-white'>
        <main className='flex-1'>
          {/* Hero Section */}
          <section className='w-full py-20 md:py-32 lg:py-44'>
            <div className='container mx-auto px-4 md:px-6 max-w-5xl'>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className='flex flex-col items-center space-y-8 text-center'
              >
                <h1 className='text-4xl font-extrabold tracking-tight sm:text-5xl md:text-6xl lg:text-7xl max-w-4xl'>
                  Transcribe, <span className='text-green-600'>Translate</span>{' '}
                  and Broadcast Speeches with Ease
                </h1>
                <p className='max-w-2xl text-gray-600 md:text-xl lg:text-2xl'>
                  TalkTranslate: The ultimate voice transcription app for
                  faith-based organizations, conferences, and events. Capture,
                  transcribe, translate and broadcast your speeches effortlessly
                  & in realtime with your audience.
                </p>
                <div className='flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4'>
                  <Link
                    href='#how-it-works'
                    className='bg-green-600 hover:bg-green-700 text-white px-8 py-3 rounded-full transition-all duration-300 transform hover:scale-105'
                  >
                    Get Started
                  </Link>
                  <Link
                    href='#features'
                    className='border border-green-600 text-green-600 hover:bg-green-50 px-8 py-3 rounded-full transition-all duration-300'
                  >
                    Learn More
                  </Link>
                  <Link
                    href='https://calendly.com/sas70/translate-30-min'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='bg-blue-600 hover:bg-blue-700 text-white px-8 py-3 rounded-full transition-all duration-300 transform hover:scale-105'
                  >
                    Contact Us
                  </Link>
                </div>
              </motion.div>
            </div>
          </section>

          {/* Features Section */}
          <section id='features' className='w-full py-20 md:py-32 bg-white'>
            <div className='container mx-auto px-4 md:px-6 max-w-6xl'>
              <h2 className='text-3xl font-bold tracking-tight sm:text-5xl text-center mb-16'>
                Key Features
              </h2>
              <div className='grid gap-12 md:grid-cols-2 lg:grid-cols-3'>
                {[
                  {
                    icon: FileText,
                    title: 'Accurate Transcription',
                    description:
                      'State-of-the-art AI technology ensures precise transcription & translation of most languages including Arabic.',
                  },
                  {
                    icon: Globe,
                    title: 'Multi-language Support',
                    description:
                      'Transcribe and translate talks in multiple languages, including Arabic.',
                  },
                  {
                    icon: Users,
                    title: 'Easy Sharing',
                    description:
                      'Get inclusive & closer to your audience by realtime multi-language translation of talks without any hassle.',
                  },
                ].map((feature, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    className='flex flex-col items-center text-center p-6 bg-green-50 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300'
                  >
                    <feature.icon className='h-16 w-16 text-green-600 mb-6' />
                    <h3 className='text-2xl font-bold mb-4'>{feature.title}</h3>
                    <p className='text-gray-600'>{feature.description}</p>
                  </motion.div>
                ))}
              </div>
            </div>
          </section>

          {/* How It Works Section */}
          <section id='how-it-works' className='w-full py-20 md:py-32 bg-white'>
            <div className='container mx-auto px-4 md:px-6 max-w-6xl'>
              <h2 className='text-3xl font-bold tracking-tight sm:text-5xl text-center mb-16'>
                How It Works
              </h2>
              <div className='grid gap-12 md:grid-cols-2 lg:grid-cols-3'>
                {[
                  {
                    icon: Users, // Replace with an appropriate icon
                    title: 'Sign-up',
                    description:
                      'Create your account to get started with our services.',
                  },
                  {
                    icon: Mic, // Replace with an appropriate icon
                    title: 'Click on Record',
                    description:
                      'You will see a QR code and your unique PIN, which you need to share with your audience.',
                  },
                  {
                    icon: FileText, // Replace with an appropriate icon
                    title: 'Start Recording',
                    description:
                      'Click Start recording (and make sure you allow the use of mic and unmute).',
                  },
                ].map((step, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    className='flex flex-col items-center text-center p-6 bg-green-50 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300'
                  >
                    <step.icon className='h-16 w-16 text-green-600 mb-6' />
                    <h3 className='text-2xl font-bold mb-4'>{step.title}</h3>
                    <p className='text-gray-600'>{step.description}</p>
                  </motion.div>
                ))}
              </div>
            </div>
          </section>

          {/* Testimonials Section */}
          <section
            id='testimonials'
            className='w-full py-20 md:py-32 bg-green-50'
          >
            <div className='container mx-auto px-4 md:px-6 max-w-6xl'>
              <h2 className='text-3xl font-bold tracking-tight sm:text-5xl text-center mb-16'>
                Trusted by reputable organisations
              </h2>
              <div className='grid gap-8 md:grid-cols-2 lg:grid-cols-3'>
                {[
                  {
                    quote:
                      "TalkTranslate has revolutionized how we share our Friday khutbahs with our community. It's an invaluable tool for dawah.",
                    author: '----------------------------------',

                    // author: 'Imam Abdullah, New York Central Mosque',
                  },
                  {
                    quote:
                      'The multi-language support has helped us reach a wider audience and break down language barriers in our diverse community.',
                    author: '----------------------------------',
                    // author: 'Ustadh Yusuf, London Islamic Center',
                  },
                  {
                    quote:
                      'The accuracy of the translation, especially with Islamic terminology, is impressive. It saves us precious time & resources.',
                    author: '----------------------------------',

                    // author: 'Sister Aisha, Toronto Masjid',
                  },
                ].map((testimonial, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    className='bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300'
                  >
                    <p className='text-gray-600 mb-6 italic'>
                      &ldquo;{testimonial.quote}&rdquo;
                    </p>
                    <p className='font-bold text-green-600'>
                      - {testimonial.author}
                    </p>
                  </motion.div>
                ))}
              </div>
            </div>
          </section>
        </main>

        <footer className='py-8 w-full border-t bg-white'>
          <div className='container mx-auto px-4 md:px-6 max-w-6xl'>
            <div className='flex flex-col md:flex-row justify-between items-center'>
              <p className='text-sm text-gray-600 mb-4 md:mb-0'>
                For support, please contact us at{' '}
                <a href='mailto:Omar@TalkTranslate.ai'>Omar@TalkTranslate.ai</a>
              </p>
              <nav className='flex gap-6'>
                <Link
                  className='text-sm text-gray-600 hover:text-green-600 transition-colors'
                  href='https://docs.google.com/document/d/10maZPdt0u9SZT6bZtsROsNFE4VB2hWuJzmHldAWWPLI/edit?usp=sharing'
                >
                  Terms of Service
                </Link>
                <Link
                  className='text-sm text-gray-600 hover:text-green-600 transition-colors'
                  href='https://docs.google.com/document/d/1kk1vw2dqT_RiS3O4spJ_9tMpRD7kGcbVpHqLoRjOLNI/edit?usp=sharing'
                >
                  Privacy
                </Link>
              </nav>
            </div>
          </div>
        </footer>
      </div>
    </ClientOnly>
  );
}
